import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from "../../features/counter/counterSlice";

const Check = () => {
  const count = useSelector((state) => state.counter.value);

  const dispatch = useDispatch();

  const handleIncrement = () => {
    dispatch(increment());
  };

  const handleDecrement = () => {
    dispatch(decrement());
  };

  return (
    <div>
      <h1>Counter: {count}</h1>
      <button
        style={{ padding: "10px", border: "1px solid black" }}
        onClick={handleIncrement}
      >
        +
      </button>
      <button
        style={{ padding: "10px", border: "1px solid black" }}
        onClick={handleDecrement}
      >
        -
      </button>
    </div>
  );
};

export default Check;
