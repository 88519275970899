import React from "react";
import "./footer.css";
import RightArrow from "../Home/utils/RightArrow";
import logo from "./logo-grey.svg";
import linkedinLogo from "./Component 4.svg";
import facebookLogo from "./Component 8.svg";
import instagramLogo from "./Component 9.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer-section">
      <div className="content">
        <p style={{ marginBottom: "15px", textDecoration: "underline" }}>
          RISK WARNING
        </p>

        <p>
          Fractioned is committed to maintaining a rigorous evaluation process,
          conducted by our proficient finance team, to vet the credentials of
          each business featured on our platform. As an organization that
          prioritizes retail investors, we enforce stringent compliance
          standards to ensure that only credible and authentic listings are made
          available for investment. Nevertheless, while these investments are
          generally backed by tangible assets, they are accompanied by a
          moderate level of risk, including potential delays in repayment. It is
          crucial to acknowledge that, although the probability of losing your
          principal investment is minimal, it remains a possibility.
        </p>

        <div className="investment-button-div">
          <div>
            <p>Plan your investment journey with Fractioned</p>

            <div className="investment-button">
              <button>
                Invest Now
                <RightArrow />
              </button>
            </div>
          </div>
        </div>

        <div className="footer-links">
          <div className="first-col">
            <div className="first-col-first-div">
              <img src={logo} alt="logo" />
            </div>

            <div className="first-col-second-div">
              <a
                href="https://www.linkedin.com/company/fractioned"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedinLogo} alt="linkedin_logo" />
              </a>

              <a
                href="https://www.instagram.com/fractioned.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookLogo} alt="facebook_logo" />
              </a>

              <a
                href="https://www.instagram.com/fractioned.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagramLogo} alt="instagram_logo" />
              </a>
            </div>
            <div className="first-col-third-div">
              <p>Stay Connected</p>
            </div>
          </div>

          <div className="second-col">
            <Link to="/" className="link">
              Home
            </Link>
            <Link to="/about-us" className="link">
              About Us
            </Link>
            <Link to="/FAQ" className="link">
              FAQs
            </Link>
          </div>

          <div className="third-col">
            <p>
              123 Finance Street, Suite 101 City, State, Phone: (123) 456-7890
              Email: contact@fractioned.com
            </p>
          </div>
        </div>

        <div id="copyright">
          <p>© 2024 Fractioned technologies. All rights reserved.</p>

          <p>Privacy policy T&C*</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
