import React, { useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "./LOGO.svg";
import axios from "axios";
import toast from "react-hot-toast";
import LoginOtp from "../login-otp/LoginOtp";
import gmailLogo from "./gmailLogo.svg";
import mobileLogo from "./phone.svg";

const Login = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);

  const [isEmailMode, setIsEmailMode] = useState(false);

  const toggleInputMode = () => {
    setIsEmailMode(!isEmailMode);
    setValue("");
  };

  const handleChange = (e) => {
    if (isEmailMode) {
      setValue(e.target.value);
    } else {
      setValue(e.target.value.replace(/[^0-9]/g, ""));
    }
    // console.log(value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/prod/user/signup",
        { number: value }
      );
      if (res.status === 200 && isEmailMode) {
        toast.success("OTP sent to your email");
      }
      if (res.status === 200 && !isEmailMode) {
        toast.success("OTP sent to your mobile number");
      }
      setShowOtpInput(true);
    } catch (error) {
      const res_error = error.response["data"];
      if (res_error) {
        toast.error(res_error);
      }
    }
  };

  const handleNewTab = (e) => {
    e.preventDefault();
    window.open("/privacy-policy", "_blank");
  };

  return (
    <div id="login">
      <div className="left-login">
        <img src={logo} alt="logo" />
      </div>
      <div className="right-login">
        {!showOtpInput ? (
          <div className="auth-form">
            <h1>Welcome to Fractioned !</h1>
            <h1 className="second-heading">Secure Access Made Simple.</h1>
            <form onSubmit={handleLogin} className="login-form">
              <input
                type="tel"
                maxLength={!isEmailMode ? "10" : undefined}
                placeholder={
                  isEmailMode ? "Enter Your Email" : "Enter Mobile Number"
                }
                value={value}
                onChange={handleChange}
                className="mobile-input"
              />

              <button className="login-button">Get OTP</button>

              <h1 className="below-heading">
                By continuing, you agree to our{" "}
                <span>
                  <a
                    href="https://drive.google.com/file/d/1p5FdcGKArqf9UrcniUfOIu_ycuzY3Mvi/view?usp=sharing"
                    target="_blank"
                  >
                    {" "}
                    Privacy Policy.
                  </a>
                </span>
              </h1>
            </form>
            <div className="divider">
              <span>or</span>
            </div>
            <div>
              <button id="gmail" onClick={toggleInputMode}>
                <img
                  src={isEmailMode ? mobileLogo : gmailLogo}
                  alt={isEmailMode ? "mobile-logo" : "gmail-logo"}
                />
                {isEmailMode ? "Mobile" : "Email"}
              </button>
            </div>
          </div>
        ) : (
          <>
            <LoginOtp length={4} value={value} />
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
