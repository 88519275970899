import React from "react";
import privacypolicy from "./privacy_policy.pdf";

const PrivacyPolicy = () => {
  return (
    <div style={{ width: "100%", height: "900px" }}>
      <embed
        src={privacypolicy}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default PrivacyPolicy;
