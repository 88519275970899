import React from 'react';
import "../../Navbar/Navbar.css"

const LoginIcon = () => {
  return (
    <div className="icon-container">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="contact-icon" 
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.37232 4.9025C5.37232 3.46253 6.55102 2.29123 8.00008 2.29123C9.44914 2.29123 10.6278 3.46253 10.6278 4.9025C10.6278 6.34179 9.44914 7.5131 8.00008 7.5131C6.55102 7.5131 5.37232 6.34179 5.37232 4.9025ZM4.4083 4.9025C4.4083 6.86978 6.0197 8.47107 8.00008 8.47107C9.98046 8.47107 11.5912 6.86978 11.5912 4.9025C11.5912 2.93455 9.98046 1.33325 8.00008 1.33325C6.0197 1.33325 4.4083 2.93455 4.4083 4.9025ZM7.31849 14.6566C7.54188 14.6633 7.76931 14.6666 8.00008 14.6666C10.2897 14.6666 13.3334 14.4126 13.3334 12.22C13.3334 9.75937 9.31899 9.75937 8.00008 9.75937C5.71044 9.75937 2.66675 10.0134 2.66675 12.2066C2.66675 12.8633 2.974 13.7493 4.43782 14.2419C4.69073 14.3286 4.9631 14.1919 5.04897 13.9419C5.13417 13.6919 4.99933 13.4206 4.74708 13.336C3.74549 12.9986 3.6301 12.53 3.6301 12.2066C3.6301 11.218 5.10063 10.7167 8.00008 10.7167C10.8995 10.7167 12.3701 11.2227 12.3701 12.22C12.3701 13.208 10.8995 13.7086 8.00008 13.7086C7.7787 13.7086 7.56067 13.7059 7.34599 13.6999C7.08838 13.7019 6.85895 13.9006 6.85157 14.1646C6.84352 14.4293 7.05283 14.6499 7.31849 14.6566Z"
          fill="#FEFEFE" 
        />
      </svg>
    </div>
  );
};

export default LoginIcon;
