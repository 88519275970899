import { useEffect } from "react";

const WatiWidget = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src =
      "https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?20632";
    script.async = true;

    // Set up widget options
    const options = {
      enabled: true,
      chatButtonSetting: {
        backgroundColor: "grey",
        ctaText: "Chat with us",
        borderRadius: "25",
        marginLeft: "0",
        marginRight: "20",
        marginBottom: "20",
        ctaIconWATI: false,
        position: "right",
      },
      brandSetting: {
        brandName: "Fractioned",
        brandSubTitle: "Building people owned business",
        brandImg: "/favicon_io/favicon.ico",
        welcomeText: "Hi there!\nHow can I help you?",
        messageText: "hello there ... ",
        backgroundColor: "#00e785",
        ctaText: "Chat with us",
        ctaIconWATI: false,
        borderRadius: "25",
        autoShow: false,
        phoneNumber: "919902370041",
      },
    };

    // Initialize the widget after the script loads
    script.onload = () => {
      if (window.CreateWhatsappChatWidget) {
        window.CreateWhatsappChatWidget(options);

        // Injecting the custom CSS after the widget is initialized
        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = `
          .wati-chat-button {
            pointer-events: none; /* Disable click events */
            cursor: default; /* Change cursor to default */
            color: inherit; /* Use parent color */
            text-decoration: none; /* Remove underline */
          }
          .wati-chat-button:hover {
            color: inherit; /* Ensure color doesn't change on hover */
          }
        `;
        document.head.appendChild(styleSheet);
      }
    };

    // Inject the script into the DOM
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to load the script only once on mount

  return null; // No JSX output as we are only injecting the script
};

export default WatiWidget;
