import React, { useState } from "react";
import "./JoinTheClub.css";
import pieChartImage from "../../Home/images/pie-chart.svg";
import pieChartData from "../../Home/images/pie-chart-data.svg";
import { toast, Toaster } from "react-hot-toast";

const JoinTheClub = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value.replace(/[^0-9]/g, ""));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    if (!phoneNumber || phoneNumber.length !== 10) {
      toast.error("Oops! We need a valid 10-digit mobile number");
      return;
    }

    try {
      const response = await fetch(
        "https://977pcmqxi4.execute-api.ap-south-1.amazonaws.com/prod/user/joinourclub",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            number: phoneNumber,
          }),
        }
      );

      const result = await response.json();
      if (result.message === "You have Already joined the group, Thankyou !") {
        toast.success("You have already joined the group, Thankyou !");
        return;
      } else {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="/abhishek_bothra.jpg"
                    alt="founder_image"
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    Abhishek Bothra
                  </p>
                  <p className="mt-1 text-sm text-gray-500">{result.message}</p>
                </div>
              </div>
            </div>
          </div>
        ));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div id="container">
      <p className="top-heading">
        Maximize Your Returns - Start Investing Today!
      </p>

      <div className="heading">
        <div className="logo">
          <img src="/images/LOGO.svg" alt="logo" />
        </div>
        <p className="above-button">
          Invest with the most powerful SME ecosystem in India
        </p>
        <div className="input-button">
          <input
            type="tel"
            placeholder="Enter mobile Number"
            maxLength={10}
            value={phoneNumber}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSubmit}>Join the club!</button>

          <Toaster position="top-center" reverseOrder={false} />
        </div>

        <p className="earn-text">Earn up to 22%* Annual Interest</p>
      </div>

      <div id="portfolio">
        <div className="investment-portfolio">
          <p>Our Investment portfolio</p>

          <div className="portfolio-table">
            <div className="portfolio-col">
              <p>
                Active <br />
                projects
              </p>
              <p className="num">4</p>
            </div>
            <div className="portfolio-col">
              <p>
                Closed <br /> projects
              </p>
              <p className="num">5</p>
            </div>
            <div className="portfolio-col">
              <p>
                Total <br /> AUM
              </p>
              <p className="num">₹6cr.</p>
            </div>
            <div
              className="portfolio-col"
              style={{ backgroundColor: "transparent" }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div style={{ backgroundColor: "transparent" }}>
                  <img
                    src={pieChartImage}
                    alt="pie-chart-image"
                    style={{ backgroundColor: "transparent" }}
                    className="pie-chart-image"
                  />
                </div>

                <div style={{ backgroundColor: "transparent" }}>
                  <img
                    src={pieChartData}
                    alt="pie-chart-data"
                    style={{ backgroundColor: "transparent" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="investment-portfolio">
          <p>Our customers believe in us</p>

          <div className="portfolio-table">
            <div className="portfolio-col">
              <p>
                Total <br />
                Userbase
              </p>
              <p className="num">5</p>
            </div>
            <div className="portfolio-col">
              <p>
                Capital & Interest <br />
                paid back
              </p>
              <p className="num">₹0.6cr.</p>
            </div>
            <div className="portfolio-col">
              <p>
                Average <br /> ROI
              </p>
              <p className="num">19%</p>
            </div>
            <div className="portfolio-col">
              <p>
                Asset <br /> backing
              </p>
              <p className="num">90%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinTheClub;
