import Navbar from "../Navbar/Navbar";
import "./Home.css";
import pieChartImage from "./images/pie-chart.svg";
import pieChartData from "./images/pie-chart-data.svg";
import boxContent1 from "./images/alternative icon.png";
import boxContent2 from "./images/liqudity icon.png";
import boxContent3 from "./images/insuredicon.png";
import boxContent4 from "./images/Robust icon.png";
import boxContent5 from "./images/investments icon.png";
import boxContent6 from "./images/boxContent6.png";
import RightArrow from "./utils/RightArrow";
import income_icon from "./images/incomeicon.svg";
import VerticalLinearStepper from "../Miui/VerticalStepper";
import CardCarousel from "../Miui/CardCarousel";
import MyAccordion from "../Miui/Accordion/MyAccordion";
import Footer from "../Footer/Footer";
import strategies_products from "./images/strategies_products.svg";
import piechart from "./images/pie-chart-third.svg";
import investment_steps from "./images/investment-steps.svg";

const Home = () => {
  return (
    <div id="home-container">
      <div id="home">
        <Navbar />
        <div id="heading-block">
          <p className="top-tagline">
            Limitless Opportunities <span>∞</span> Boundless Untapped Market
          </p>

          <p className="heading">
            Invest with the most powerful <br /> <span>SME</span> ecosystem in
            India
          </p>

          <p className="sub-heading">Earn up to 22%* Annual Interest</p>

          <div className="invest-now-btn">
            <button>Invest Now</button>
          </div>

          <p className="supporting-text">Investment starts at ₹5,00,000</p>
        </div>

        <div id="portfolio1">
          <div className="investment-portfolio1">
            <p>Our Investment portfolio</p>

            <div className="portfolio-table1">
              <div className="portfolio-col1">
                <p>
                  Active <br />
                  projects
                </p>
                <p className="num">4</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Closed <br /> projects
                </p>
                <p className="num">5</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Total <br /> AUM
                </p>
                <p className="num">₹6cr.</p>
              </div>
              <div
                className="portfolio-col1"
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div style={{ backgroundColor: "transparent" }}>
                    <img
                      src={pieChartImage}
                      alt="pie-chart-image"
                      style={{ backgroundColor: "transparent" }}
                      className="pie-chart-image"
                    />
                  </div>

                  <div style={{ backgroundColor: "transparent" }}>
                    <img
                      src={pieChartData}
                      alt=""
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="investment-portfolio1">
            <p>Our customers believe in us</p>

            <div className="portfolio-table1">
              <div className="portfolio-col1">
                <p>
                  Total <br />
                  Userbase
                </p>
                <p className="num">5</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Capital & Interest <br />
                  paid back
                </p>
                <p className="num">₹0.6cr.</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Average <br /> ROI
                </p>
                <p className="num">19%</p>
              </div>
              <div className="portfolio-col1">
                <p>
                  Asset <br /> backing
                </p>
                <p className="num">90%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="second-section">
        <div className="heading-block">
          <p className="second-section-heading">
            Design for a better future, Today.
          </p>
        </div>

        <div id="second-heading-grid">
          <div className="box-content">
            <div style={{ backgroundColor: "transparent" }}>
              <img
                src={boxContent1}
                style={{ backgroundColor: "transparent" }}
                alt="icon-image"
              />
            </div>

            <p className="box-heading">Wide Range of Alternatives</p>

            <p className="box-heading-content">
              We curate and handpick SMEs from different industries so that our
              customers can enjoy steady and predictable returns tailored as per
              their investment goals
            </p>
          </div>

          <div className="box-content">
            <div style={{ backgroundColor: "transparent" }}>
              <img
                src={boxContent2}
                style={{ backgroundColor: "transparent" }}
                alt="icon-image"
              />
            </div>

            <p className="box-heading">Tailored Liquidity for You</p>

            <p className="box-heading-content">
              We offer opportunities ranging from 60 days to 60 months, with
              interest rates from 15% to 24% per annum—significantly higher than
              traditional fixed deposits
            </p>
          </div>

          <div className="box-content">
            <div style={{ backgroundColor: "transparent" }}>
              <img
                src={boxContent3}
                style={{ backgroundColor: "transparent" }}
                alt="icon-image"
              />
            </div>

            <p className="box-heading">Insured & Managed</p>

            <p className="box-heading-content">
              Your investments are insured and backed by real-world assets—land,
              machinery, or inventory— managed by experienced business experts
              to ensure both security and growth
            </p>
          </div>

          <div className="box-content">
            <div style={{ backgroundColor: "transparent" }}>
              <img
                src={boxContent4}
                style={{ backgroundColor: "transparent" }}
                alt="icon-image"
              />
            </div>

            <p className="box-heading">Resilient & Robust</p>

            <p className="box-heading-content">
              Invest in sectors that thrive through economic fluctuations, like
              export-driven industries. These opportunities are designed to
              ensure growth and stability, offering a buffer against market
              downturns
            </p>
          </div>

          <div className="box-content">
            <div style={{ backgroundColor: "transparent" }}>
              <img
                src={boxContent5}
                style={{ backgroundColor: "transparent" }}
                alt="icon-image"
              />
            </div>

            <p className="box-heading">Experience Your Investments</p>

            <p className="box-heading-content">
              Step beyond the numbers and projections - schedule a visit to
              witness the impact of your investment firsthand. Let’s bring your
              investment journey to life together!
            </p>
          </div>

          <div className="box-content">
            <div style={{ backgroundColor: "transparent" }}>
              <img
                src={boxContent6}
                style={{ backgroundColor: "transparent" }}
                alt="icon-image"
              />
            </div>

            <p className="box-heading">Impact beyond returns</p>

            <p className="box-heading-content">
              Your investments do more than just yield returns—they contribute
              to building sustainable businesses and turn aspirations into
              reality. Join us in making a positive impact while achieving
              your financial goals
            </p>
          </div>
        </div>

        <button className="request_call_button">
          Request a call
          <RightArrow />
        </button>

        <div className="below_call_btn_heading">
          <p>
            Ready to diversify your investments across multiple sectors for
            greater returns?
          </p>
        </div>
      </div>

      <div id="third-section">
        <div className="strategies_products">
          <div>
            <img src={strategies_products} alt="strategies_images" />
          </div>
          <h1>Strategies & Product</h1>
        </div>
        <div className="explore_products">
          <h1>Explore our product & strategies</h1>
        </div>

        <div className="third-section-flex">
          <div className="third-section-box">
            <div className="third-section-box-top">
              <div className="third-section-top-heading">
                <div style={{ backgroundColor: "transparent" }}>
                  <img src={income_icon} alt="income_icon" />
                </div>
                <h1 style={{ backgroundColor: "transparent" }}>Income</h1>
              </div>
              <h1 className="third-section-top-description">
                Prioritizes capital preservation and income generation by
                allocation predominantly to credit assets
              </h1>
            </div>

            <div className="third-section-box-mid">
              <div className="third-section-box-mid-left">
                <div className="third-section-box-mid-left-top">
                  <div>
                    <button></button>
                    <p>Credit : 60%</p>
                  </div>
                  <div>
                    <button></button>
                    <p>Equity : 40%</p>
                  </div>
                </div>

                <div className="third-section-box-mid-left-bottom">
                  <div>
                    <img src={piechart} alt="pie-chart-image" />
                  </div>
                </div>
              </div>
              <div className="third-section-box-mid-right">
                <div className="third-section-box-mid-right-col">
                  <h1>Primary source of return</h1>
                  <h1>
                    Contractual <br /> Interest Income
                  </h1>
                </div>

                <div className="third-section-box-mid-right-col">
                  <h1>Risk-return profile</h1>
                  <h1>
                    Generally <br /> LOW
                  </h1>
                </div>

                <div className="third-section-box-mid-right-col">
                  <h1>Public market equivalent</h1>
                  <h1>Bonds</h1>
                </div>
              </div>
            </div>

            <div className="third-section-box-last">
              <div className="third-section-box-last-flex">
                <h1 style={{ borderTop: "1px solid #ed9196" }}>
                  Purchase Financing
                </h1>
                <h1 style={{ borderTop: "1px solid #f9d776" }}>
                  Receivable Financing
                </h1>
                <h1 style={{ borderTop: "1px solid #7ccafb" }}>
                  Working Capital Financing
                </h1>
              </div>
            </div>
          </div>

          <div className="third-section-box">
            <div className="third-section-box-top">
              <div className="third-section-top-heading">
                <div style={{ backgroundColor: "transparent" }}>
                  <img src={income_icon} alt="income_icon" />
                </div>
                <h1 style={{ backgroundColor: "transparent" }}>Growth</h1>
              </div>
              <h1 className="third-section-top-description">
                Targets long-term capital appreciation through equity-focused
                investing with potential for dividend payouts.
              </h1>
            </div>

            <div className="third-section-box-mid">
              <div className="third-section-box-mid-left">
                <div className="third-section-box-mid-left-top">
                  <div>
                    <button></button>
                    <p>Credit : 70%</p>
                  </div>
                  <div>
                    <button></button>
                    <p>Equity : 30%</p>
                  </div>
                </div>

                <div className="third-section-box-mid-left-bottom">
                  <div>
                    <img src={piechart} alt="pie-chart-image" />
                  </div>
                </div>
              </div>
              <div className="third-section-box-mid-right">
                <div className="third-section-box-mid-right-col">
                  <h1>Primary source of return</h1>
                  <h1>
                    Dividend <br /> Appreciation
                  </h1>
                </div>

                <div className="third-section-box-mid-right-col">
                  <h1>Risk-return profile</h1>
                  <h1>
                    Generally <br /> HIGH
                  </h1>
                </div>

                <div className="third-section-box-mid-right-col">
                  <h1>Public market equivalent</h1>
                  <h1>Stocks</h1>
                </div>
              </div>
            </div>

            <div className="third-section-box-last">
              <div className="third-section-box-last-flex">
                <h1 style={{ borderTop: "1px solid #ed9196" }}>
                  Purchase Financing
                </h1>
                <h1 style={{ borderTop: "1px solid #f9d776" }}>
                  Receivable Financing
                </h1>
                <h1 style={{ borderTop: "1px solid #7ccafb" }}>
                  Working Capital Financing
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="fourth-section">
        <div className="fourth-section-top">
          <h1 className="fourth-section-heading">
            Ready to Invest? <span>Here’s How!</span>
          </h1>
        </div>

        <div className="fourth-section-mid">
          <VerticalLinearStepper />
        </div>

        <div className="fourth-section-bottom">
          <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-center md:text-left">
            INVEST NOW
          </p>
        </div>
      </div>

      <div className="fifth-section">
        <h1>
          “What” <span>our customer’s say?</span>
        </h1>

        <CardCarousel />
      </div>

      <div id="sixth-section">
        <h1>
          Have questions?<span> We’ve got you.</span>
          <p>
            We’ve answered the most common questions to guide you through our
            services and investment options with ease.
          </p>
        </h1>

        <div className="faq-grid">
          <MyAccordion />
        </div>

        <div className="sixth-section-last">
          <p>Have a question in mind?</p>
          <p>
            Contact us, support center 24/7 <span>Schedule a Call</span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
