import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import "./MyAccordion.css";

const data = [
  {
    question: "1 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
  {
    question: "2 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
  {
    question: "3 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
  {
    question: "4 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
  {
    question: "5 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
  {
    question: "6 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
  {
    question: "7 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
  {
    question: "8 What is working capital investment?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta debitis sed delectus impedit mollitia maiores minus vitae inventore ipsa rem!",
  },
];

const MyAccordion = () => {
  return (
    <>
      <Grid id="main-accordion">
        {data.map((item, index) => (
          <Box key={index}>
            <Accordion allowToggle>
              <AccordionItem
                className="accordion-box"
                border="0.5px solid"
                borderColor="#E9E9E9"
                borderRadius="lg"
                p={5}
                backgroundColor="#F6F6F7"
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      _expanded={{ bg: "gray.100" }}
                      borderBottomRadius={isExpanded ? 0 : "md"}
                      className="accordion-button"
                    >
                      <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box flex="1" textAlign="left" className="question">
                          {item.question}
                        </Box>

                        {isExpanded ? (
                          <CloseIcon fontSize="10px" />
                        ) : (
                          <AddIcon fontSize="11px" />
                        )}
                      </Flex>
                    </AccordionButton>

                    <AccordionPanel pb={4} className="answer">
                      {item.answer}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default MyAccordion;
