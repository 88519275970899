import React from "react";
import "../../Navbar/Navbar.css";

const ContactIcon = () => {
  return (
    <svg
      className="contact-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 14C2.14737 13.563 2.28647 13.149 2.42684 12.7353C2.63342 12.1241 2.84318 11.5142 3.04307 10.9011C3.06514 10.8235 3.05766 10.7404 3.02206 10.668C1.33507 7.6 2.62451 3.82561 5.84922 2.46917C9.31424 1.01209 13.1861 3.09054 13.8962 6.74582C14.4914 9.80935 12.4702 12.8531 9.41323 13.4997C8.0404 13.7901 6.72168 13.6315 5.46216 13.0012C5.38764 12.9687 5.30408 12.9633 5.22598 12.9859C4.20742 13.3044 3.18885 13.6283 2.1722 13.951C2.1235 13.9662 2.07416 13.979 2 14ZM6.12582 4.6833C5.83267 4.64094 5.59521 4.68871 5.40009 4.87439C4.83522 5.41327 4.63872 6.06383 4.8106 6.82608C4.93792 7.38184 5.25145 7.83855 5.57134 8.29557C6.47691 9.59022 7.65621 10.4909 9.19265 10.9276C9.86554 11.1186 10.469 11.0779 11.0512 10.6852C11.5042 10.3794 11.6493 9.94119 11.6356 9.42365C11.6321 9.29626 11.5758 9.19753 11.4615 9.14243C11.0041 8.92268 10.5486 8.69655 10.0855 8.49113C9.85154 8.38667 9.72835 8.44113 9.56507 8.65037C9.41483 8.84147 9.256 9.0265 9.10417 9.21696C9.04496 9.29116 8.98289 9.30072 8.89663 9.266C7.98024 8.89911 7.2698 8.29207 6.75701 7.45063C6.70067 7.35827 6.70131 7.2933 6.77707 7.21399C6.88478 7.10491 6.98406 6.98779 7.07404 6.86366C7.18354 6.7076 7.30576 6.53434 7.22778 6.33624C7.04062 5.85851 6.83722 5.38779 6.63065 4.91834C6.55903 4.75591 6.42471 4.66609 6.23468 4.68679C6.19836 4.68821 6.16198 4.68705 6.12582 4.6833Z"
        fill="#0E0E0E"
      />
    </svg>
  );
};

export default ContactIcon;
