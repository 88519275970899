// import React from "react";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
// import "@splidejs/react-splide/css";
// import img1 from "../Home/images/growthicon.svg"

// const CardCarousel = () => {
//   const testimonials = [
//     {
//       imgSrc: img1,
//       name: "Animesh Singh",
//       testimonial: `In my experience here, I found the treatment given to patients
//       to be excellent. All reports were ready at the committed time, and everything
//       was so efficient and adequately taken care of, that I am considering making
//       the Velachery clinic my regular source of care.`,
//     },
//     {
//       imgSrc: img1,
//       name: "Navtej Anand",
//       testimonial: `The nurses, radiology technician, and the phlebotomist all were very helpful.
//       Getting an appointment was easy and the waiting time for a doctor was also very less.
//       Everything was easy and hassle-free.`,
//     },
//     {
//       imgSrc: img1,
//       name: "Virat Kohli",
//       testimonial: `The billing was fast and the phlebotomist and radiology technician
//       were very professional. Reports were ready on time, and the doctor directed nicely
//       about the diagnosis and follow-ups.`,
//     },
//     {
//       imgSrc: img1,
//       name: "Nita Ambani",
//       testimonial: `I particularly liked the atmosphere there. The doctors are very good at explaining
//       the diagnosis, treatments, follow-ups, and referrals. The reports were ready on time,
//       and I would like to come here for my regular healthcare services.`,
//     },
//     {
//       imgSrc: img1,
//       name: "Deepika Padukone",
//       testimonial: `During the course of my treatment, the staff was very supportive and
//       took really good care of me. The services provided at the clinic as well as the
//       reports of tests were provided on time.`,
//     },
//   ];

//   return (
//     <div>
//       <Splide
//         options={{
//           type: "loop",
//           perPage: 3,
//           autoplay: true,
//           interval: 5000,
//           arrows: false,
//           pagination: true,
//           pauseOnHover: false,
//           color: "black",
//         }}
//       >
//         {testimonials.map((item, index) => (
//           <SplideSlide key={index}>
//             <div className="splide__slide">
//               <div className="homepage-testimonial-subdiv1">
//                 <img src={item.imgSrc} alt={item.name} />
//                 <p>{item.name}</p>
//               </div>
//               <hr className="homepage-hr" />
//               <div className="homepage-testimonial-subdiv2">
//                 <p>{item.testimonial}</p>
//               </div>
//             </div>
//           </SplideSlide>
//         ))}
//       </Splide>
//     </div>
//   );
// };

// export default CardCarousel;
import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import img1 from "../Home/images/growthicon.svg";
import "./CardCarousel.css";
import { color } from "framer-motion";

const CardCarousel = () => {
  const testimonials = [
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident omnis doloribus doloremque animi aut pariatur, qui exercitationem accusantium.",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident omnis doloribus doloremque animi aut pariatur, qui exercitationem accusantium.",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident omnis doloribus doloremque animi aut pariatur, qui exercitationem accusantium.",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident omnis doloribus doloremque animi aut pariatur, qui exercitationem accusantium.",
    },
    {
      imgSrc: img1,
      name: "Mukesh Ambani",
      testimonial:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem, quas. Provident omnis doloribus doloremque animi aut pariatur, qui exercitationem accusantium.",
    },
  ];

  return (
    <div className="carousel-container">
      <Splide
        options={{
          type: "loop",
          perPage: 3,
          perMove: 1,
          gap: "1rem",
          pagination: true,
          arrows: true,
          breakpoints: {
            640: {
              perPage: 1,
            },
            768: {
              perPage: 2,
            },
          },
        }}
      >
        {testimonials.map((item, index) => (
          <SplideSlide key={index}>
            <div className="testimonial-card">
              <img
                src={item.imgSrc}
                alt={`${item.name}'s testimonial`}
                className="testimonial-image"
              />
              <h3 className="testimonial-name">{item.name}</h3>
              <p className="testimonial-text">{item.testimonial}</p>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default CardCarousel;
